import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import fingerpointer from "../../../../../assets/images/finger-pointer.png";
import downloadIcon from "../../../../../assets/images/download-icon.svg";
import {
  planStatuses,
  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS,
} from "../../../../../constants";
import LoadingButton from "../../../../generic-components/button";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import useStepper from "../../../../../hooks/useStepper";
import { downloadApplicationForm } from "../../../../../graphql/queries/manageMySharedCost";
import { formatCurrency } from "../../../../../helpers";

const PlanHeader = ({ headerText }) => (
  <Stack direction="row" alignItems="center">
    <Box className="icon-container">
      <img className="icon" src={fingerpointer} alt="finger-pointer-icon" />
    </Box>
    <Typography className="heading ml-10">{headerText}</Typography>
  </Stack>
);

PlanHeader.propTypes = {
  headerText: PropTypes.string,
};

const PlanStatus = ({ planDetails }) => (
  <Stack direction="row" alignItems="center" className="mt-18">
    <Typography className="status-heading">Plan Status</Typography>
    <Box
      className={
        planStatuses[planDetails?.plan_status?.name]
          ? `status-container ${
              planStatuses[planDetails?.plan_status?.name]
            } ml-10`
          : "status-container awaiting ml-10"
      }
    >
      <Typography className="">{planDetails?.plan_status?.name}</Typography>
    </Box>
  </Stack>
);

PlanStatus.propTypes = {
  planDetails: PropTypes.object,
};

const PlanInfo = ({ planDetails, amendedPlan, planLength }) => {
  const { setSaveError, setErrorToast } = useStepper();
  const navigate = useNavigate();

  const [downloadForm, { loading: downloadLoading }] = useLazyQuery(
    downloadApplicationForm
  );

  const handleDownloadForm = async (planId) => {
    downloadForm({
      variables: {
        plan_id: planId,
      },
      onCompleted: (data) => {
        const applicationFormLink = data?.plan_details?.plans?.application_form;
        window.open(applicationFormLink, "_blank");
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };
  return (
    <Box className="info-container">
      <Box className="info">
        <Typography className="plan">
          Plan start date:{" "}
          {moment(planDetails?.created_at).format("D MMMM YYYY")}
        </Typography>
        <Typography className="content mt-10">
          AVC amount per payroll:{" "}
          {formatCurrency(planDetails?.total_avc_amount_requested)}
        </Typography>
        <Typography className="content mt-10">
          Payroll frequency: {planDetails?.avc_interval || ""}
        </Typography>
      </Box>
      {!amendedPlan ? (
        <Box className="button-container">
          {!["Cancelled", "Opted-out", "Opt Out Pending"].includes(
            planDetails?.plan_status?.name
          ) && planLength < 2 ? (
            <Stack
              direction={{ lg: "row", md: "row", sm: "column" }}
              spacing={2}
            >
              {planDetails?.plan_status?.name === "Accepted" ? (
                <LoadingButton
                  buttonTitle="Amend My Shared Cost AVC"
                  trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                  disabled={downloadLoading}
                  handleClick={() =>
                    navigate("/amend_my_shared_cost_avc", {
                      state: {
                        backUrl: "/manage_my_shared_cost_avc",
                      },
                    })
                  }
                  styleClass="filled-button amend mt-10"
                />
              ) : null}
              <LoadingButton
                buttonTitle="Cancel My Shared Cost AVC"
                trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                handleClick={() => navigate("/cancel_my_plan")}
                styleClass="filled-button cancel mt-10"
                disabled={downloadLoading}
              />
            </Stack>
          ) : null}
          <Stack alignItems="flex-end" className="mt-10">
            <LoadingButton
              buttonTitle="Download Application Form"
              trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
              disabled={downloadLoading}
              loading={downloadLoading}
              handleClick={() => handleDownloadForm(planDetails?.id)}
              styleClass="filled-button download"
              icon={
                <img
                  src={downloadIcon}
                  style={{ padding: "0.5rem" }}
                  alt="icon"
                />
              }
            />
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

PlanInfo.propTypes = {
  planDetails: PropTypes.object,
  amendedPlan: PropTypes.bool,
  planLength: PropTypes.number,
};

const PlanDetails = ({
  planDetails,
  amendedPlan,
  planStatusBg,
  headerText,
  planLength,
}) => {
  return (
    <>
      <Box
        className="banner-container"
        sx={{ backgroundImage: `url(${planStatusBg})` }}
      >
        <PlanHeader headerText={headerText} />
        <PlanStatus planDetails={planDetails} />
      </Box>
      <PlanInfo
        planDetails={planDetails}
        amendedPlan={amendedPlan}
        planLength={planLength}
      />
    </>
  );
};

PlanDetails.propTypes = {
  organisation: PropTypes.object,
  planDetails: PropTypes.object,
  headerText: PropTypes.string,
  planStatusBg: PropTypes.string,
  amendedPlan: PropTypes.bool,
  planLength: PropTypes.number,
};

const mapStateToProps = (state) => ({
  organisation: state?.organisation?.organisation,
});

export default connect(mapStateToProps)(PlanDetails);
