import { Box, Container, Stack, Typography } from "@mui/material";
import ButtonSection from "./ButtonSection";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FingerPointer from "../../../../../assets/images/finger-pointer.png";
import SharedCostIcon from "../../../../../assets/images/s-to-s-icon.png";
import useStepper from "../../../../../hooks/useStepper";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Loader from "../../../../generic-components/loader";
import {
  getSchemeDetails,
  getStandardAVCPlan,
} from "../../../../../graphql/queries/manageMySharedCost";
import ContributionCalculatorModal from "../../shared-cost-application/modal/ContributionCalculatorModal";
import StandardAVCDialog from "./StandardAVCDialog";
import { schemeTypeNames } from "../../../../../constants/multiForm";
import isEmpty from "lodash.isempty";
import { useNavigate } from "react-router-dom";
import {
  checkActiveSchemes,
  getLegacyAppURL,
  mmmUniversalRedirection,
} from "../../../../../helpers";
import { mmmThemes } from "../../../../../constants";

const HomePageButtonSection = ({ user, scheme, organisation }) => {
  const navigate = useNavigate();
  const { setSaveError, setErrorToast, personalDetails, webinarAttendance } =
    useStepper();
  const [schemeDetails, setSchemeDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openAVCDialog, setOpenAVCDialog] = useState(false);
  const [standardAVCScheme, setStandardAVC] = useState(null);
  const [isAlreadyApplySAVC, setAlreadyApplySAVC] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [fetchSchemeDetails] = useLazyQuery(getSchemeDetails);
  const [fetchStandardAVCPlan] = useLazyQuery(getStandardAVCPlan);

  const getSchemeDetailsURLs = async () => {
    fetchSchemeDetails({
      fetchPolicy: "no-cache",
      variables: {
        scheme_id: scheme.scheme_id,
      },
      onCompleted: (data) => {
        setSchemeDetails(data?.scheme_details);
        setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const getStandardAVCPlanDetails = () => {
    fetchStandardAVCPlan({
      fetchPolicy: "no-cache",
      variables: {
        user_id: personalDetails?.id,
      },
      onCompleted: (data) => {
        setAlreadyApplySAVC(
          data?.cs_prior_user_details_aggregate?.aggregate?.count
        );
      },
      onError: (error) => {
        setIsLoading(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const handleActiveSchemes = () => {
    const requiredActiveScheme = checkActiveSchemes(personalDetails);

    if (!requiredActiveScheme) {
      navigate("/scheme_not_found");
      return;
    }

    const standardAVC = personalDetails?.active_schemes?.find(
      (scheme) =>
        scheme?.scheme_type_name?.toLowerCase() ===
        schemeTypeNames?.STANDARD_AVC?.toLowerCase()
    );
    setStandardAVC(standardAVC);
    getStandardAVCPlanDetails();
  };

  const handleCloseAVCDialog = () => {
    setOpenAVCDialog(false);
  };

  useEffect(() => {
    if (!isEmpty(personalDetails)) {
      handleActiveSchemes();
    }
  }, [personalDetails]);

  useEffect(() => {
    if (scheme?.scheme_id) {
      getSchemeDetailsURLs();
    }
  }, [scheme]);

  return (
    <Container>
      {isLoading ? (
        <Box className="mt-100">
          <Loader />
        </Box>
      ) : (
        <>
          <Box className="button-padding-box mt-10">
            <Stack
              direction={{ md: "row", sm: "row", xs: "column" }}
              spacing={1}
              className={standardAVCScheme ? "mt-50" : "mt-30"}
            >
              <Box
                id="apply-btn"
                component="img"
                alt="Finger Pointer Icon"
                className="large-pointer-icon"
                src={FingerPointer}
              />
              <Stack direction="column" spacing={1}>
                <ButtonSection
                  heading="New to Shared Cost AVCs?"
                  buttonTitle="Click here to make your Shared Cost AVC application"
                  navigation={organisation?.new_form}
                  link={
                    organisation?.new_form
                      ? "/new-application-form"
                      : `${getLegacyAppURL(
                          personalDetails?.sub_domain
                        )}/users/${user.id}/new_scavc_application`
                  }
                />
                {standardAVCScheme ? (
                  <Box
                    className="standard-avc-box"
                    onClick={() => setOpenAVCDialog(true)}
                  >
                    <Typography className="avc-heading">
                      Rather apply for Standard AVC,
                    </Typography>
                    <Typography className="avc-heading">
                      Click here to continue
                    </Typography>
                  </Box>
                ) : null}
              </Stack>
            </Stack>
            <Stack
              direction={{ md: "row", sm: "row", xs: "column" }}
              spacing={1}
              className={standardAVCScheme ? "" : "mt-18"}
            >
              <Box
                component="img"
                alt="Shared Cost Icon"
                className="large-pointer-icon"
                src={SharedCostIcon}
              />
              <ButtonSection
                heading="Already pay standard AVCs?"
                buttonTitle="Click here to convert your standard AVC to Shared Cost AVC"
                link={`${getLegacyAppURL(personalDetails?.sub_domain)}/users/${
                  user.id
                }/convert_existing_avc`}
              />
            </Stack>
          </Box>
          <Box className="button-box-group mt-30">
            <Box className="button-box">
              <ButtonSection
                heading="Want to know how much your pot could be worth? Use the projection calculator below!"
                buttonTitle="Projection Calculator"
                handleClick={() => {
                  mmmUniversalRedirection(
                    personalDetails?.sub_domain,
                    "/employee/cms/shared-cost-avc-calculator"
                  );
                }}
              />
            </Box>
            <Box className="button-box">
              <ButtonSection
                heading="Find out the maximum amount you can contribute using the calculator below."
                buttonTitle="Maximum Contribution Calculator"
                handleClick={() => setOpenModal(true)}
                navigation={true}
              />
            </Box>
            <Box className="button-box">
              <ButtonSection
                heading="Download and read the FAQs and Ts and Cs documents below."
                newTab={true}
                buttonTitle="FAQ"
                secondButtonTitle="Ts & Cs"
                link={schemeDetails?.faq}
                secondLink={schemeDetails?.terms_and_conditions}
              />
            </Box>
          </Box>
          <Box className="discover-box mt-30">
            <Typography className="discover-typography">
              Discover more using the below resources
            </Typography>
          </Box>
          <Box className="d-flex">
            <Box
              className={
                webinarAttendance
                  ? "button-box-group bg-footer mt-18"
                  : "button-box-group bg-footer sm-bg-footer mt-18"
              }
            >
              <ButtonSection
                buttonTitle="Book your place on a webinar"
                handleClick={() => {
                  mmmUniversalRedirection(
                    personalDetails?.sub_domain,
                    `/employee/webinars/list/${mmmThemes.MY_RETIREMENTS_MATTERS}/${process.env.REACT_APP_MMM_RETIREMENT_THEME_ID}`
                  );
                }}
              />
              {webinarAttendance ? (
                <ButtonSection
                  buttonTitle="Book your individual meeting"
                  link={process.env.REACT_APP_INDIVIDUAL_MEETING_URL}
                />
              ) : null}
            </Box>
          </Box>
          <ContributionCalculatorModal
            open={openModal}
            setOpen={setOpenModal}
            applyButtonTitle="Create new Shared Cost AVC"
            convertButtonTitle="Convert existing AVC"
            islargeApplyButton={true}
            showConvertButton={true}
            showCloseButton={false}
          />
          <StandardAVCDialog
            open={openAVCDialog}
            onClose={handleCloseAVCDialog}
            standardAVCScheme={standardAVCScheme}
            isAlreadyApplySAVC={isAlreadyApplySAVC}
          />
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.user?.user,
    organisation: state?.organisation?.organisation,
    scheme: state?.scheme?.scheme,
  };
};

HomePageButtonSection.propTypes = {
  user: PropTypes.object,
  scheme: PropTypes.object,
  organisation: PropTypes.object,
};

export default connect(mapStateToProps)(HomePageButtonSection);
