import React, { useCallback, useEffect, useState } from "react";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./main.scss";
import Header from "./components/layout/header/Header";
import Footer from "./components/layout/footer/Footer";
import ThankyouPage from "./components/shared/thankyou-page/ThankyouPage";
import SharedCostApplicationPage from "./components/screens/registered-user/shared-cost-application/SharedCostApplicationPage";
import Chatbtn from "./components/generic-components/Chatbtn";
import EmailVerificationPage from "./components/screens/registered-user/email-verification/EmailVerifcationPage";
import VerifiedScreen from "./components/screens/registered-user/verified-screen/VerifiedScreen";
import AfterSignin from "./components/screens/registered-user/After-signin-screen/AfterSignin";
import SignIn from "./components/screens/registered-user/sign-in/SignIn";
import RegisterationPage from "./components/screens/unregistered-user/RegisterationPage";
import MyInvestmentAdvice from "./components/ningi-journey/myInvestmentAdvice/MyInvestmentAdvice";
import { useToast } from "./hooks/useToast";
import useStepper from "./hooks/useStepper";
import usePlanDetails from "./hooks/usePlanDetails";
import { getUserDetails } from "./graphql/queries/user";
import { useLazyQuery } from "@apollo/client";
import { getTempPlan } from "./graphql/queries/tempPlan";
import { setUser } from "./reducers/userReducer";
import { useDispatch } from "react-redux";
import { setForm } from "./reducers/formReducer";
import {
  checkOrganisationSubDomain,
  compileOrganisationData,
  findActiveSchemeDetails,
  legacyAppRedirection,
  transformTempInvestmentChoices,
} from "./helpers";
import isEmpty from "lodash.isempty";
import {
  setOrganisation,
  setOrganisationId,
  setUserRolesOrganisations,
} from "./reducers/organisationReducer";
import { getOrganisationDetails } from "./graphql/queries/OrganisationDetails";
import { UseScrollTop } from "./hooks/useScrollTop";
import {
  DEFAULT_TITLE,
  documentPathTitles,
  newApplicationFormPath,
} from "./constants/path";
import { TRACKING_NAF_OPTIONS } from "./constants";
import { getCookie } from "./utils/cookies";
import { avcUniqueTrackingCode } from "./utils";
import CalculatorView from "./components/screens/registered-user/calculator/Calculator";
import { setApplicationType } from "./reducers/multiFormReducer";
import { pensionType, schemeTypeNames } from "./constants/multiForm";
import store from "./store";
import HomePage from "./components/screens/registered-user/home-page/HomePage";
import IMOverlayButton from "./components/generic-components/IMOverlayButton";
import ManageMySharedCostAVCPage from "./components/screens/registered-user/manage-my-shared-cost/ManageMySharedCostAVCPage";
import PageNotFound from "./components/shared/PageNotFound";
import { getWebinarAttendance } from "./graphql/queries/events";
import CancelMySharedCostAVC from "./components/screens/registered-user/manage-my-shared-cost/cancel-my-shared-cost-avc/CancelMySharedCostAVC";
import AmendMySharedCostAVCPage from "./components/screens/registered-user/manage-my-shared-cost/amend-my-shared-cost-avc/AmendMySharedCostAVCPage";
import ChangePortalPage from "./components/screens/registered-user/change-portal/ChangePortalPage";
import { setScheme } from "./reducers/schemeReducer";
import ContactPage from "./components/screens/registered-user/contact-page/ContactPage";
import { sendUserExitedEvent, sendUserLandingPageEvent } from "./utils/emarsys";
import StaffBenefitsPage from "./components/screens/registered-user/staff-benefits-page/StaffBenefits";
import SchemeNotFound from "./components/screens/registered-user/scheme/SchemeNotFound";
import AdminRoutes from "./components/screens/private-routes/AdminRoutes";
import EmployeeRoutes from "./components/screens/private-routes/EmployeeRoutes";

const App = () => {
  const {
    saveError,
    setInitialValues,
    savedToast,
    setSavedToast,
    errorToast,
    successToast,
    setActiveStep,
    personalDetails,
    setPersonalDetails,
    calculateAndSetActiveStep,
    setErrorToast,
    setSaveError,
    setSuccessToast,
    setTrackingCode,
    trackingCode,
    fullScreenLoading,
    setFullScreenLoading,
    webinarAttendance,
    setWebinarAttendance,
    enableFetchUser,
    formValues,
  } = useStepper();
  const { fetchCompletePlanDetails } = usePlanDetails();

  const { showToast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFetched, setIsFetched] = useState(false);
  const { pathname } = useLocation();

  const tempTrackingCode = avcUniqueTrackingCode();

  const updateTrackingCode = () => {
    const userTrackingCode = personalDetails.tracking_code;

    if (personalDetails.latest_temp_plan) {
      setTrackingCode(userTrackingCode || trackingCode);
    } else if (!trackingCode) {
      setTrackingCode(tempTrackingCode);
    }
  };

  const redirectionToNHSHome = (pathname, isNHS, subDomain) => {
    if (
      [
        newApplicationFormPath.HOME,
        newApplicationFormPath.DEFAULT_APPLICATION_PATH,
        newApplicationFormPath.MANAGE_MY_SHARED_COST_AVC,
        newApplicationFormPath.AMEND_MY_SHARED_COST_AVC,
        newApplicationFormPath.CANCEL_MY_PLAN,
        newApplicationFormPath.MY_INVESTMENT_ADVICES,
        newApplicationFormPath.REDIRECT_INVESTMENT_GUIDE,
      ].includes(pathname) &&
      isNHS
    ) {
      legacyAppRedirection(subDomain, "/schemes/my_salary_sacrifice_avc");
    }
  };

  const handleTabClose = useCallback(() => {
    if (formValues?.id) {
      sendUserExitedEvent(formValues);
    }
  }, [formValues]);

  useEffect(() => {
    updateTrackingCode();
  });

  window.onbeforeunload = handleTabClose;

  useEffect(() => {
    if (personalDetails.id) {
      sendUserLandingPageEvent(pathname, personalDetails);
    }
  }, [pathname, personalDetails]);

  useEffect(() => {
    if (store?.getState()?.multiForm?.pensionType) {
      const subDomain =
        store?.getState()?.organisation?.organisation?.sub_domain;
      const isNHS =
        store.getState()?.multiForm?.pensionType === pensionType.NHS;

      redirectionToNHSHome(pathname, isNHS, subDomain);
    }
  }, [pathname, store]);

  useEffect(() => {
    if (
      !isEmpty(personalDetails) &&
      !personalDetails.is_employer_and_employee &&
      !personalDetails.backend_user &&
      personalDetails?.active_schemes?.find(
        (scheme) => scheme.pension_type === pensionType.NHS
      ) &&
      [newApplicationFormPath.CHANGE_PORTAL].includes(pathname)
    ) {
      legacyAppRedirection(
        personalDetails?.sub_domain,
        "/schemes/my_salary_sacrifice_avc"
      );
    }
    if (
      !isEmpty(personalDetails) &&
      !personalDetails.role_id &&
      ![newApplicationFormPath.CHANGE_PORTAL].includes(pathname)
    ) {
      navigate("/change_portal");
    }
  }, [pathname, personalDetails]);

  useEffect(() => {
    const stepNumber = store?.getState()?.form?.form?.step_number;
    if (
      store?.getState()?.form?.form?.step_number &&
      [
        newApplicationFormPath.NEW_APPLICATION_FORM,
        newApplicationFormPath.NHS_APPLICATION_FORM,
        newApplicationFormPath.REDIRECT_APPLICATION_FORM,
      ].includes(pathname)
    )
      calculateAndSetActiveStep(stepNumber);
  }, [pathname, store]);

  useEffect(() => {
    dispatch(setUser({ token: getCookie("user_session") }));
    dispatch(setOrganisationId(personalDetails.organisation_id));
  }, []);

  const [fetchOrganisationDetails] = useLazyQuery(getOrganisationDetails, {
    onCompleted: (data) => {
      const currentOrganisation = data.organisation_details;
      dispatch(setOrganisation(currentOrganisation));
      dispatch(
        setUserRolesOrganisations(
          compileOrganisationData(data?.users_roles || [])
        )
      );
    },
    onError: (error) => {
      setFullScreenLoading(false);
      setSaveError(error);
      setErrorToast(true);
    },
  });

  const [fetchWebinarAttendance] = useLazyQuery(getWebinarAttendance, {
    onCompleted: (data) => {
      setWebinarAttendance(
        data?.booked_user_presentations_aggregate?.aggregate?.count
          ? true
          : false
      );
    },
    onError: (error) => {
      setFullScreenLoading(false);
      setSaveError(error);
      setErrorToast(true);
    },
  });

  const [fetchTempPlan] = useLazyQuery(getTempPlan, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (isFetched) return;

      const tempPlan = data.temp_plans?.find((plan) => plan);

      setInitialValues(tempPlan);

      if (tempPlan) {
        dispatch(
          setForm({
            ...tempPlan,
            investmentChoices: transformTempInvestmentChoices(
              tempPlan.temp_plan_investments
            ),
          })
        );

        if (
          [
            newApplicationFormPath.NEW_APPLICATION_FORM,
            newApplicationFormPath.NHS_APPLICATION_FORM,
            newApplicationFormPath.REDIRECT_APPLICATION_FORM,
          ].includes(pathname)
        )
          calculateAndSetActiveStep(tempPlan.step_number);
      } else setActiveStep(0);

      setIsFetched(true);
    },
    onError: (error) => {
      setActiveStep(0);
      setIsFetched(false);
      setFullScreenLoading(false);
      setSaveError(error);
      setErrorToast(true);
    },
  });

  const [fetchUserDetails] = useLazyQuery(getUserDetails, {
    fetchPolicy: "no-cache",
    refetchWritePolicy: "overwrite",
    onCompleted: (data) => {
      setIsFetched(false);
      const userId = data?.me?.id;
      const organisationId = data?.me?.organisation_id;
      const activeSchemes = data?.me?.active_schemes || [];
      const userDetails = { ...data?.me, active_schemes: activeSchemes };
      const isNHS = activeSchemes.find(
        (scheme) => scheme?.pension_type === pensionType.NHS
      );

      if (organisationId) {
        checkOrganisationSubDomain(userDetails?.sub_domain);
      }
      setPersonalDetails(userDetails);

      const organisationPensionType = isNHS
        ? pensionType.NHS
        : pensionType.LGPS;

      const organisationSchemeType = isNHS
        ? schemeTypeNames.SALARY_SACRIFICE_AVC
        : schemeTypeNames.SHARED_COST_AVC;

      const schemeDetails = findActiveSchemeDetails(
        activeSchemes,
        organisationPensionType,
        organisationSchemeType
      );

      if (userId && organisationId) {
        fetchCompletePlanDetails(userId, organisationId, true);
      } else {
        setFullScreenLoading(false);
      }

      dispatch(setScheme(schemeDetails));
      dispatch(setUser(userDetails));
      dispatch(setApplicationType(!!isNHS));

      redirectionToNHSHome(pathname, !!isNHS, userDetails?.sub_domain);
    },
    onError: (error) => {
      setSaveError(error);
      setActiveStep(0);
      setFullScreenLoading(false);
      setErrorToast(true);
    },
  });

  useEffect(() => {
    if (!isEmpty(personalDetails) && !isFetched) {
      if (personalDetails.organisation_id && personalDetails.role_name) {
        fetchOrganisationDetails({
          variables: {
            organisation_id: personalDetails.organisation_id,
            user_id: personalDetails.id,
            organisation_role: personalDetails.role_name,
          },
        });
        fetchWebinarAttendance({
          variables: {
            organisation_id: personalDetails.organisation_id,
            user_id: personalDetails.id,
          },
        });
      }

      if (personalDetails.latest_temp_plan) {
        fetchTempPlan({
          variables: {
            id: personalDetails.latest_temp_plan,
          },
        });
      } else {
        setInitialValues();
      }
    }
  }, [personalDetails]);

  useEffect(() => {
    const pageDetails = documentPathTitles.find((page) =>
      pathname.includes(page.path)
    );

    document.title = pageDetails?.title || DEFAULT_TITLE;
  }, [pathname]);

  useEffect(() => {
    fetchUserDetails();
  }, [enableFetchUser]);

  return (
    <Box className="App">
      {fullScreenLoading ? (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          className="back-drop"
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          {saveError &&
            showToast(
              saveError.message,
              errorToast,
              setErrorToast,
              "error",
              saveError.overRideCustom
            )}
          {savedToast &&
            showToast("Application Saved", savedToast, setSavedToast)}
          {successToast &&
            showToast(successToast, successToast, setSuccessToast)}
          <UseScrollTop />
          <Header />
          <Routes>
            <Route exact path="/" element={<Navigate to="/home" replace />} />
            <Route path="/home" element={<HomePage />} />
            <Route element={<EmployeeRoutes />}>
              <Route
                path="/new-application-form"
                element={<SharedCostApplicationPage />}
              />
              <Route
                path="/nhs-application-form"
                element={<SharedCostApplicationPage />}
              />
              <Route
                path="/manage_my_shared_cost_avc"
                element={<ManageMySharedCostAVCPage />}
              />
              <Route
                path="/amend_my_shared_cost_avc"
                element={<AmendMySharedCostAVCPage />}
              />
              <Route
                path="/cancel_my_plan"
                element={<CancelMySharedCostAVC />}
              />
              <Route
                path="/my_investment_advice"
                element={<MyInvestmentAdvice />}
              />
              <Route
                path="/redirect-avc_investment_guide"
                element={<Navigate to="/my_investment_advice" replace />}
              />
              <Route path="/thankyou" element={<ThankyouPage />} />
              <Route path="/events" element={<Navigate to="/home" replace />} />
              <Route
                path="/events/:title"
                element={<Navigate to="/home" replace />}
              />
              <Route
                path="/my_bookings"
                element={<Navigate to="/home" replace />}
              />
              <Route
                exact
                path="/max-contribution-calculator"
                element={<CalculatorView />}
              />
            </Route>
            <Route element={<AdminRoutes />}>
              <Route
                path="/partners_events"
                element={<Navigate to="/home" replace />}
              />
            </Route>
            <Route path="/profile" element={<Navigate to="/home" replace />} />
            <Route path="/staff_benefits" element={<StaffBenefitsPage />} />
            <Route path="/change_portal" element={<ChangePortalPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/scheme_not_found" element={<SchemeNotFound />} />
            <Route
              path="/emailverification"
              element={<EmailVerificationPage />}
            />
            <Route path="/verifiedscreen" element={<VerifiedScreen />} />
            <Route path="/sign_in" element={<SignIn />} />
            <Route path="/aftersignin" element={<AfterSignin />} />
            <Route path="/register" element={<RegisterationPage />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          {webinarAttendance &&
          [newApplicationFormPath.EVENTS].includes(pathname) ? (
            <IMOverlayButton trackingDetails={TRACKING_NAF_OPTIONS} />
          ) : null}
          <Chatbtn trackingDetails={TRACKING_NAF_OPTIONS} />
          <Footer />
        </>
      )}
    </Box>
  );
};

export default App;
