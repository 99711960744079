import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MoneyPig from "../../../../../assets/images/money-pig.png";
import FingerPointer from "../../../../../assets/images/finger-pointer.png";
import LoadingButton from "../../../../generic-components/button";
import { TRACKING_HOME_OPTIONS } from "../../../../../constants";
import NoPlanDialog from "./NoPlanDialog";
import useStepper from "../../../../../hooks/useStepper";
import usePlan from "../../../../../hooks/usePlan";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash.isempty";
import { legacyAppRedirection } from "../../../../../helpers";

const HomeDescriptionContent = ({ organisation, user }) => {
  const [open, setOpen] = useState(false);
  const { personalDetails } = useStepper();
  const { isLatestActivePlan } = usePlan();
  const navigate = useNavigate();

  const handleRedirectionToApplication = () => {
    if (organisation?.new_form) {
      navigate("/new-application-form");
      return;
    }
    legacyAppRedirection(
      personalDetails?.sub_domain,
      `/users/${user.id}/new_scavc_application`
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      !isEmpty(personalDetails) &&
      !personalDetails?.latest_plan &&
      !personalDetails?.latest_temp_plan &&
      !isLatestActivePlan
    ) {
      setOpen(true);
    }
  }, [personalDetails]);

  return (
    <Container>
      <Stack
        direction={{ lg: "row", md: "row", sm: "column" }}
        justifyContent="space-between"
      >
        <Stack direction="column">
          <Box className="shrink-box">
            <Typography className="mt-18">
              This is your one stop platform with all the tools and resources
              you need to help you learn more about your Shared Cost Additional
              Voluntary Contribution (Shared Cost AVC) benefit.
            </Typography>
            <Typography className="mt-18">
              <strong>{organisation?.name}</strong> is working in partnership
              with My Money Matters to facilitate this valuable benefit, which
              is available to Local Government Pension Scheme (LGPS) members.
            </Typography>
            <Typography className="mt-18">
              The team of experts at My Money Matters are here to help us with
              the administration and management of the scheme, as well as
              providing you with a helping hand so you can look forward to a
              happy, financially healthy future.
            </Typography>
            <Stack direction="row">
              <Box
                component="img"
                alt="finger-pointer"
                className="finger-pointer"
                src={FingerPointer}
              />
              <LoadingButton
                buttonTitle="Ready to apply? Click here"
                trackingDetails={TRACKING_HOME_OPTIONS}
                handleClick={handleRedirectionToApplication}
                styleClass={"btn primary-clr-btn mt-12"}
              />
            </Stack>
          </Box>
        </Stack>
        <Box className="mt-5 saving-jars-container">
          <Box
            component="img"
            alt="money-pig"
            className="money-pig"
            src={MoneyPig}
          />
        </Box>
      </Stack>
      <NoPlanDialog
        open={open}
        onClose={handleClose}
        scrollToApply={handleRedirectionToApplication}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
    user: state?.user?.user,
  };
};

HomeDescriptionContent.propTypes = {
  organisation: PropTypes.object,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(HomeDescriptionContent);
