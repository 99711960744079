import React from "react";
import { Box, Container } from "@mui/material";
import PlanDetailsComponent from "./PlanDetails";
import PlanGuidanceComponent from "./PlanGuidance";
import planStatusBg from "../../../../../assets/images/bg.png";
import PropTypes from "prop-types";
import CustomerServiceFooter from "./CustomerServiceFooter";
import usePlan from "../../../../../hooks/usePlan";
import StaffBenefitsWidget from "../../staff-benefits-page/StaffBenefitsWidget";
import { TRACKING_MANAGE_MY_SHARED_COST_OPTIONS } from "../../../../../constants";

const ManageMySharedCostWithPlans = ({ plans }) => {
  const { isLatestActivePlan } = usePlan();

  return (
    <Container>
      <>
        <Box className="plan-detail-container space-from-top">
          <PlanDetailsComponent
            planDetails={
              plans?.length > 1
                ? plans.find((plan) => plan?.plan_status?.name === "Accepted")
                : plans[0]
            }
            headerText="Current Plan"
            planStatusBg={planStatusBg}
            amendedPlan={false}
            planLength={plans?.length}
          />
        </Box>
        {plans?.length > 1 ? (
          <Box className="plan-detail-container mt-30">
            <PlanDetailsComponent
              planDetails={
                plans.find((plan) => plan?.plan_status?.name !== "Accepted") ||
                plans[1]
              }
              headerText="Amendment Request"
              planStatusBg={planStatusBg}
              amendedPlan={true}
              planLength={plans?.length}
            />
          </Box>
        ) : (
          <Box className="plan-guidance-container mt-18">
            <PlanGuidanceComponent planDetails={plans[0]} />
          </Box>
        )}
        <CustomerServiceFooter />
        {isLatestActivePlan ? (
          <StaffBenefitsWidget
            trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
          />
        ) : null}
      </>
    </Container>
  );
};

ManageMySharedCostWithPlans.propTypes = {
  plans: PropTypes.array,
};

export default ManageMySharedCostWithPlans;
